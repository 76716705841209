.modal-dialog {
    .modal-content {
        border-radius: 0;
        border-bottom: 11px solid $clr-blue;
        
        .modal-header {
            border-bottom: none;

            .btn-close {
                width: 2rem;
                height: 2rem;
                background-size: 1.6rem;
            }
        }
        .modal-body {
            ol, ul li {
                font-size: 1.5rem;
            }
            ol li {
                padding-left: 2rem;

                &::before {
                    font-size: 1.5rem;
                }
            }
        }
    }
}