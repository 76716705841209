.programspage {
    padding-top: 5rem;

    h1 {
        color: $clr-navy;
    }

    .programs {
        &__wrapper {
            padding-top: 4rem;
        }
        &__toggler {
            font-size: 1.1rem;
            color: $clr-gray;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 1rem;

            a.toggle-view {
                background: transparent;
                border: none;
                box-shadow: none;
                color: $clr-gray;
                font-size: 2rem;
                margin: 0 .5rem;
                color: #e7e7e7;

                &.active {
                    color: $clr-gray;
                }

            }
        }

        &__gridview {
            display: none;

            &.active {
                display: block;
            }

            .programs__view__item {
                &-container {
                    width: 100%;
                    padding-top: 100%;
                    position: relative;
                    margin-bottom: 4rem;

                    &::before {
                        content: ' ';
                        width: calc(100% + 1rem);
                        height: 7rem;
                        background-color: $clr-blue;
                        position: absolute;
                        top: -.5rem;
                        left: -.5rem;
                    }
                }
                
                &-image {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    &::after {
                        content: ' ';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index: 0;
                        background-color: $clr-navy-overlay;
                    }
                }

                &-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                &-title {
                    a {
                        text-decoration: none;

                        h2 {
                            font-family: $lemonmilk;
                            font-weight: 500;
                            font-size: 1.8rem;
                            color: $clr-white;
                            margin-bottom: 1rem;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                    }
                }
                &-degree {
                    color: $clr-white;
                    font-size: 1.3rem;
                }
                &-location {
                    position: absolute;
                    bottom: 2rem;
                    
                    &-item {
                        padding: .5rem;
                        color: $clr-white;
                        font-size: 1.2rem;
                        background-color: $clr-green;
                    }
                }
            }
        }

        &__listview {
            display: none;

            &.active {
                display: block;
            }

            .programs__view__item {
                display: flex;
                border-bottom: 1px solid #cccccc;
                padding: 2rem;
                justify-content: space-between;

                &-container {
                    width: 100%;
                }
                &-image {
                    display: none;
                }
                &-degree {
                    display: none;
                }
                &-inner {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    @include media-breakpoint-down(md) {
                        flex-flow: column;
                        align-items: flex-end;
                    }
                }
                &:last-of-type {
                    border-bottom: none;
                }
                &-title {
                    a {
                        text-decoration: none;
                        h2 {
                            color: $clr-navy;
                            font-size: 1.8rem;
                            font-weight: 600;
                            margin: 0;
                            text-transform: capitalize;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
                &-location {
                    &-item {
                        padding: .5rem;
                        color: $clr-white;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    &__sidebar {
        padding-top: 3rem;

        &__title {
            h3 {
                font-family: $lemonmilk;
                font-size: 1.4rem;
                font-weight: 500;
                color: $clr-gray;
                letter-spacing: 2px;
            }
        }

        &__filter {
            margin-bottom: 3rem;
            form {
                input, select {
                    background: transparent;
                    border: 2px solid $clr-navy;
                    border-radius: 50px;
                    color: $clr-navy;
                    padding: 1rem;
                }
                input[type=search] {
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 95% center;
                    padding: 15px 4rem 15px 15px;
                    margin-bottom: 15px;
                }
                select {
                    -webkit-appearance: none;
                    /*webkit browsers */
                    -moz-appearance: none;
                    /*Firefox */
                    appearance: none;
                    position: relative;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                    background-position: 95% center;
                    background-repeat: no-repeat;
                    padding-right: 4rem;
                    
                }
                .form-checkbox {
                    background-color: $clr-gray-light;
                    padding: 1rem 2rem;
                    border-radius: 50px;
                    font-size: 1.6rem;
                }

                .btn {
                    width: 100%;
                }
            }
        }

        &__text {
            font-size: 1.3rem;
    
            &-title {
                font-weight: 600;
                color: $clr-navy;
            }

            p {
                font-size: 1.3rem;
            }
        }
    }
}