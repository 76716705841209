.statistics {
    background: $clr-green;
    border-radius: 10px;
    padding: 3rem;
    color: $clr-white;

    &__text {
        font-family: $lemonmilk;
        font-size: 3rem;
        font-weight: 500;
    }
    &__number {
        font-family: $lemonmilk;
        font-size: 11.7rem;
        font-weight: bold;

        &-small {
            font-size: 7rem;
        }
    }
    &__button {
        padding-bottom: 3rem;
    }
}