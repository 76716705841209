// ——————————————————————————————————————
//	=Lemon Milk
// ——————————————————————————————————————
@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-Light.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-LightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-BoldIitalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/lemon_milk/LEMONMILK-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}




// ——————————————————————————————————————
//	=Poppins
// ——————————————————————————————————————
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
