.search-wrapper {
	display: none;
	visibility: hidden;
	position: fixed;
	background-color: rgba(0,0,0,0.95);
	height: 100vh;
	width: 100%;
	z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.full-search-form {
	position: relative;
	top: 40%;
}
.full-search-form input {
	width: 100%;
}
.full-search-form input[type="text"] {
	background-color: transparent;
	border-width: 0 0 1px 0;
	border-color: rgba(255,255,255,0.75);
	color: rgba(255,255,255,0.75);
	padding: 15px 0;
}
.full-search-form button[type="submit"] {
	background-color: transparent;
	border: none;
	font-size: 24px;
	color: rgba(255,255,255,0.75);
	position: relative;
	top: 20px;
	padding: 0;
}
.close-form {
	position: relative;
	text-align: right;
	top: 30px;
	font-size: 30px;
	color: rgba(255,255,255,0.75);
	cursor: pointer;
}