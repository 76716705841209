.breadcrumb {
	padding: 3rem 0;
	background-color: transparent;
	margin-bottom: 0;

	font-family: $poppins;
	font-weight: normal;
	font-size: 1.5rem;


	a {
		text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
	}

	&__wrapper {
		padding: .9rem 0;
	}

	&__inner {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -1rem; // offset last row's bottom spacing
	}

	&__spacer {
		margin: 0 .7rem;
		width: .6rem;
		display: inline-flex;
	}

	&__spacer,
	&__item {
		margin-bottom: 1rem;

		&--last {
			font-weight: 600;
		}
	}

}
