.searchresult {
    margin-bottom: 2rem;
    
    &-hero {
        height: max-content;
        margin-top: -148.19px;
        z-index: 0;
        position: relative;
    
        &__image {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    
        &__text {
            color: $clr-white;
            position: absolute;
            bottom: 2rem;
            width: 100%;
        }
    
        &__form {
            form {
                input[type=search] {
                    background: transparent;
                    border: none;
                    border-bottom: 2px solid $clr-white;
                    border-radius: 0;
                    color: $clr-white;
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath fill='%23ffffff' d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 95% center;
                    padding-right: 4rem;
                    margin-bottom: 2rem;

                    &::placeholder {
                        color: $clr-white;
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            margin-top: 0;
            margin-bottom: 0;
    
            &__text {
                background-color: $clr-purple;
                position: relative;
                padding: 2rem 0 1rem 0;
            }
        }   
             
    }

    &__content {
        h2 {
            font-family: $poppins;
            font-size: 2.5rem;
            font-weight: 600;
            letter-spacing: normal;
        }
    }


    &__item {

        &-title {
            a {
                text-decoration: none;

                h3 {
                    font-family: $poppins;
                    font-size: 2.5rem;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    letter-spacing: normal;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-excerpt {
            font-size: 1.6rem;
        }
    }
}