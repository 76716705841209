// ——————————————————————————————————————
//	=Fonts
// ——————————————————————————————————————
// Custom fonts
// From fonts.google.com, ran through transfonter.org (did not fix vertical metrics)
$lemonmilk: 'LemonMilk', serif;
$poppins: 'Poppins', Helvetica, Arial, sans-serif;

// Booststrap font variables, works as an override
$font-family-sans-serif: $poppins;
$font-family-serif: $poppins;
$font-family-base: $poppins;
$headings-font-family: $lemonmilk;
$headings-font-weight: bold;


// ——————————————————————————————————————
//	=Colors
// ——————————————————————————————————————
// Custom colors
$clr-black: #000000;
$clr-white: #ffffff;
$clr-gray: rgba(0,0,0,.53);
$clr-gray-light: #f7f7f7;
$clr-gold: #ffb600;
$clr-navy: #002d69;
$clr-blue: #298fc2;
$clr-blue-light: #e9f4f9;
$clr-green: #047e8d;
$clr-green-light: #e9f1f1;
$clr-orange: #ee7209;
$clr-purple: #622763;
$clr-topmenu-divider: rgba(255,255,255,.16);
$clr-mainmenu-divider: rgba(0,0,0,.14);
$clr-navy-overlay: rgba(0,45,105,.6);
$clr-blue-bg: rgba(39,144,192,.16);
$clr-orange-bg: rgba(238,114,9,.16);
$clr-green-bg: rgba(4,126,141,.16);



// Customize some defaults
// Bootstrap colors variables, works as an override
// Color
$primary: $clr-navy;
$secondary: $clr-blue;
$body-color: $clr-black;
$body-bg: $clr-white;
$border-radius: .4rem;
$success: #7952b3;


// ——————————————————————————————————————
//	=General
// ——————————————————————————————————————
$assets-path: '../../';


// Toggle global options
$enable-gradients: true;
$enable-shadows: true;
