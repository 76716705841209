.page404 {
    background: url(../images/bg_logo.png) no-repeat top center;
    background-size: cover;
    padding-top: 13rem;
    padding-bottom: 20rem;
    color: $clr-navy;

    form {
        display: flex;

        input[type=text] {
            width: 100%;
            color: $clr-navy;
            border: none;
            border-bottom: 2px solid $clr-navy;
            background: transparent;
            font-size: 2.5rem;

            ::placeholder {
                color: $clr-navy !important;
            }
        }

        button[type="submit"] {
            text-indent: -999px;
            overflow: hidden;
            width: 40px;
            padding: 0;
            margin: 0;
            border: none;
            border-radius: inherit;
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='38' height='38' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
            cursor: pointer;
            opacity: 0.7;
            border-bottom: 2px solid $clr-navy;
        }
    }
}