.newsdetail {
    &__content {
        &-category {
            font-family: $lemonmilk;
            font-size: 1.8rem;
            padding-left: 1rem;
            position: relative;
            margin-bottom: 1rem;

            &::before {
                content: ' ';
                position: absolute;
                width: 5px;
                height: 2.2rem;
                background-color: $clr-blue;
                left: 0;
                top:0;
            }
        }
        &-title {
            h2 {
                font-family: $poppins;
                font-weight: 600;
                letter-spacing: normal;
                margin-bottom: 1rem;
            }
        }
        &-date {
            font-size: 2rem;
            margin-bottom: 3rem;

            &::after {
                content: ' ';
                width: 20rem;
                height: 5px;
                background-color: $clr-blue;
                margin-top: 3rem;
                display: block;
            }
        }
        &-featured {
            float: right;
            margin-left: 2rem;
            margin-bottom: 2rem;
            max-width: 40%;
        }
        &-button {
            border-top: 4px solid $clr-green;
            width: 250px;
            padding-top: 1rem;

            a {
                font-family: $lemonmilk;
                color: $clr-green;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}