.newspage {
    background: $clr-gray-light;
    padding-top: 5rem;

    h1 {
        color: $clr-navy;
    }

    &__content {
        article {
            background: $clr-white;
            border-radius: 10px;
            box-shadow: rgb(0 0 0 / 10%) 0px 4px 5px;
            padding: 2rem;
            margin-bottom: 3rem;
        }

        &__main {
            &-image {
                margin-bottom: 1rem;
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            &-category {
                font-family: $lemonmilk;
                font-size: 1.3rem;
                padding-left: 1rem;
                position: relative;
                margin-bottom: 1rem;

                &::before {
                    content: ' ';
                    position: absolute;
                    width: 5px;
                    height: 2.2rem;
                    background-color: $clr-blue;
                    left: 0;
                    top:0;
                }

                a {
                    text-decoration: none;
                    margin-right: 1rem;
                    color: $clr-black;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &-title {
                a {
                    text-decoration: none;
                    h2 {
                        font-family: $poppins;
                        font-size: 3.2rem;
                        font-weight: 600;
                        letter-spacing: unset;
                        margin-bottom: 1rem;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &-date {
                font-size: 1.2rem;
            }

            &-button {
                font-size: 3rem;
                display: flex;
                justify-content: flex-end;
                margin-top: -3rem;

                a {
                    color: $clr-blue;
                    text-decoration: none;
                    padding-right: 1rem;
                    transition: .25s ease-in-out;  

                    &:hover {
                        padding-right: 0;
                    }
                }
            }
        }

        &__item {

            &-wrapper {
                position: relative;
                height: 100%;
            }

            &-image {
                margin-bottom: 1rem;

                img {
                    width: 100%;
                }
            }
            &-category {
                font-family: $lemonmilk;
                font-size: 1.3rem;
                padding-left: 1rem;
                position: relative;
                margin-bottom: 1rem;

                &::before {
                    content: ' ';
                    position: absolute;
                    width: 5px;
                    height: 2.2rem;
                    background-color: $clr-blue;
                    left: 0;
                    top:0;
                }

                a {
                    text-decoration: none;
                    margin-right: 1rem;
                    color: $clr-black;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &-title {
                a {
                    text-decoration: none;
                    h3 {
                        font-family: $poppins;
                        font-size: 2.7rem;
                        font-weight: 600;
                        letter-spacing: unset;
                        margin-bottom: 1rem;
                        margin-top: 0;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &-date {
                font-size: 1.2rem;
                margin-bottom: 1rem;
            }

            &-button {
                font-size: 3rem;
                display: flex;
                justify-content: flex-end;
                position: absolute;
                bottom: 0;
                right: 0;

                a {
                    color: $clr-blue;
                    text-decoration: none;
                    padding-right: 1rem;
                    transition: .25s ease-in-out;  

                    &:hover {
                        padding-right: 0;
                    }
                }
            }

        }
    }

    &__sidebar {
        padding-top: 3rem;

        &__title {
            h3 {
                font-family: $lemonmilk;
                font-size: 1.4rem;
                font-weight: 500;
                color: $clr-gray;
                letter-spacing: 2px;
            }
        }

        &__filter {
            margin-bottom: 3rem;
            form {
                input, select {
                    background: transparent;
                    border: 2px solid $clr-navy;
                    border-radius: 50px;
                    color: $clr-navy;
                    padding: 1rem;
                }
                input[type=search] {
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 95% center;
                    padding-right: 4rem;
                }
                select {
                    -webkit-appearance: none;
                    /*webkit browsers */
                    -moz-appearance: none;
                    /*Firefox */
                    appearance: none;
                    position: relative;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                    background-position: 95% center;
                    background-repeat: no-repeat;
                    padding-right: 4rem;
                    
                }
                .btn {
                    width: 100%;
                }
            }
        }

        &__archive {
            .accordion-item {
                background-color: transparent;
                border-left: none;
                border-right: none;
                border-radius: 0;
            }
            .accordion-header {
                button {
                    font-family: $lemonmilk;
                    color: $clr-navy;
                    background: transparent;

                    &::after {
                        background-image: none;
                    }
                }
            }
            .accordion-body {
                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        a {
                            text-decoration: none;
                            color: $clr-blue;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}