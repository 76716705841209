.carousel {
    &-control {
        &-next, &-prev {
            &-icon {
                background-image: none;

                &::after {
                    font-size: 3.1rem;
                }
            }
        }
    }
}