.image-overlay {
    position: relative;
    
    &-text {
        opacity: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        top: 0;
        width: 100%;
        height: 100%;
        color: $clr-white;
        font-size: 4rem;
        z-index: 2;
        font-weight: 600;
        transition: .25s ease-in-out;

        &::before {
            content: ' ';
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.5);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            display: block;
    
        }
    }

    &:hover &-text {
        opacity:1;
    }
}