.facultystaff {

    &__detail {
        article {
            background: $clr-white;
            border-radius: 10px;
            box-shadow: rgb(0 0 0 / 10%) 0px 4px 5px;
            padding: 2rem;
            margin-bottom: 3rem;
        }

        &__wrapper {
            background-color: $clr-blue-light;
            border-radius: 10px;
            padding: 2rem;
            margin-bottom: 2rem;
            display: flex;

            @include media-breakpoint-down(md) {
                flex-flow: column;
            }
        }

        &__item {
            &-image img {
                padding-right: 2rem;
                width: 100%;
                height: auto;
                
                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }
            }

            &-info {
                width: 100%;
            }
            &-name {
                a {
                    text-decoration: none;
                    h2 {
                        font-family: $poppins;
                        font-size: 3rem;
                        font-weight: 600;
                        letter-spacing: unset;
                        margin: 0 0 1rem 0;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &-location {
                font-family: $lemonmilk;
                font-size: 1.6rem;
                padding-left: 1rem;
                position: relative;
                margin-bottom: 1rem;
                float: right;
                margin-top: -4rem;

                &::before {
                    content: ' ';
                    position: absolute;
                    width: 5px;
                    height: 2.2rem;
                    background-color: $clr-blue;
                    left: 0;
                    top:0;
                }

                @include media-breakpoint-down(md) {
                    float: none;
                    margin-top: unset;
                }
            }
            &-department {
                font-weight: 600;
                font-size: 2rem;
                margin-top: 2rem;
            }
            &-job {
                font-size: 1.8rem;
            }
            &-contact {
                margin-top: 2rem;
                display: flex;
                font-size: 1.8rem;

                &-phone {
                    font-weight: 500;
                    margin-right: 2rem;
                }

                @include media-breakpoint-down(md) {
                    flex-flow: column;
                }
            }

            &-title {
                font-size: 2.4rem;
                font-weight: 600;
                letter-spacing: normal;
            }

            &-button {
                border-top: 4px solid $clr-green;
                width: 250px;
                padding-top: 1rem;
                margin-bottom: 2rem;
    
                a {
                    font-family: $lemonmilk;
                    color: $clr-green;
                    text-decoration: none;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}