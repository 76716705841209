.multi-select-container {
    display: inline-block;
    position: relative;
    width: 100%;
}

.multi-select-menu {
    position: absolute;
    left: 0;
    top: 34px;
    z-index: 9;
    float: left;
    min-width: 100%;
    background: $clr-white;
    margin: 1em 0;
    border: 1px solid $clr-navy;
    display: none;
}

.multi-select-menuitems {
    max-width: 100%;
    padding: 0.5em 0;
    display: flex;
    flex-wrap: wrap;
}
.multi-select-menuitem {
    display: block;
    padding: 0em 0em 0em 4rem;
    white-space: nowrap;
    position: relative;
    color: $clr-navy;
    flex: 100%;

    input[type=checkbox] {
        width: 1.6rem;
        height: 1.6rem;
    }

}

.multi-select-presets {
    border-bottom: 1px solid $clr-navy;
}

.multi-select-menuitem input {
    position: absolute;
    margin-top: 0.25em;
    margin-left: -2.5rem;
    opacity: 1;
}
.multi-select-menuitem.selected::after {
    background: $clr-navy;
    border: $clr-navy solid 2px;
}

.multi-select-button {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 15px;
    color: $clr-navy;
    border: 2px solid $clr-navy;
    border-radius: 50px;
  /* needed */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  /* SVG background image */
    background: none;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.multi-select-button:after {
    content: "\f078";
    font-family: "Font Awesome 6 Pro";
    font-style: normal;
    font-weight: 300;
    text-decoration: inherit;
    color: #002d69;
    font-size: 2.5rem;
    position: absolute;
    bottom: 16px;
    right: 16px;
    text-rendering: optimizeLegibility;
}
.multi-select-container--open .multi-select-menu {
    display: block;
}

.multi-select-container--open .multi-select-button:after {
    border-width: 0 0.4em 0.4em 0.4em;
    border-color: transparent transparent #999 transparent;
}

.multi-select-container--positioned .multi-select-menu {
    /* Avoid border/padding on menu messing with JavaScript width calculation */
    box-sizing: border-box;
}

.multi-select-container--positioned .multi-select-menu label {
    /* Allow labels to line wrap when menu is artificially narrowed */
    white-space: normal;
}