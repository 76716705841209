.owl-theme {
    .owl-nav {
        position: absolute;
        width: 100%;
        bottom: 3.2rem;    

        .owl-prev {
            left: calc(50% - 80px);
            position: absolute;
        }
        
        .owl-next {
            left: calc(50% + 50px);
            position: absolute;
        }

        button:focus {
            border: 2px solid blue;
        }
    }

    .owl-dots {
        padding-top: 2rem;
        .owl-dot {
            span {
                width: 24px;
                height: 24px;
                border: 3px solid $clr-navy;
                background: $clr-white;
            }

            &.active {
                span {
                    background: $clr-navy;
                }
            }

            &:hover {
                span {
                    background: $clr-navy;
                }
            }
            
            &:focus {
                border: 2px solid blue;
            }
        }
    }
}