/*!
Theme Name: SCC
Author: MRW Connected
Author URI: https://mrwconnected.com/
Version: 1.0
*/

// Abstracts
@import "site/abstracts/bootstrap-overrides"; // Must include prior to importing and compiling Bootstrap
@import "site/abstracts/variables";
@import "site/abstracts/mixins";

//Vendor
@import "bootstrap/scss/bootstrap";

// Basic
@import "site/base/fonts";
@import "site/base/icon-list";
@import "site/base/common";
@import "site/base/header";
@import "site/base/footer";
@import "site/base/sidebar";
@import "site/base/override";

//Components
@import "site/components/search_form";
@import "site/components/breadcrumbs";
@import "site/components/tables";
@import "site/components/accordion";
@import "site/components/tabs";
@import "site/components/carousels";
@import "site/components/statistics";
@import "site/components/modal";
@import "site/components/multiselect";
@import "site/components/image_overlay";
@import "site/components/owl_carousel";

//Pages
@import "site/pages/404";
@import "site/pages/home";
@import "site/pages/subpage";
@import "site/pages/news";
@import "site/pages/news/detail";
@import "site/pages/search_result";
@import "site/pages/facultystaff";
@import "site/pages/facultystaff/detail";
@import "site/pages/students_page";
@import "site/pages/programs";
@import "site/pages/programs/detail";