.nav-tabs {
    border-top: 2px solid $clr-green;
    border-bottom: none;
    margin-bottom: 2rem;
    
    .nav-item {
        button {
            border: none;
            font-family: $lemonmilk;
            font-size: 2rem;
            color: $clr-black;
            font-weight: 500;
            margin: 0;

            &.active {
                color: $clr-green;
                background: $clr-green-light;
                border-bottom: 6px solid $clr-green;
            }

        }
    }
}