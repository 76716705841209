.footer {
    background-color: $clr-gray-light;
    padding-top: 4rem;
    padding-bottom: 4rem;

    &__logo {
        width: 11rem;
    }
    
    &__text p {
        color: $clr-gray;
        font-size: 1.4rem;
    }

    &__social {
        ul {
            list-style: none;
            display: flex;
            flex-flow: row;
            padding: 0;
            margin: 0;
            li {
                margin-right: 2rem;

                a {
                    color: $clr-gray;
                    font-size: 2.4rem;

                    &:hover {
                        color: $clr-black;
                    }
                }
            }
        }
    }

    &__menu__wrapper {
        color: $clr-gray;
        font-size: 1.2rem;
        line-height: 2rem;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        a {
            color: $clr-gray;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $clr-black;
            }
        }
    }

    &__menu {
        background: $clr-green;
        padding: 2rem 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            height: 100%;

            li {
                height: 100%;
                display: inline-flex;

                &:not(:last-child) {
                    &::after {
                        content: " ";
                        background-color: $clr-topmenu-divider;
                        height: 100%;
                        width: 2px;
                        align-self: center;
                        margin-left: 1rem;
                        display: inline-block;
                    }
                }

                a {
                    color: $clr-white;
                    font-size: 1.4rem;
                    text-decoration: none;
                    padding: 0 2rem;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &__logo {
            width: 20%;

            img {
                width: 100%;
            }
        }
        &__social {
            padding-top: 0;
        }

        &__menu {
            ul {
                li {
                    height: auto;
                    &:not(:last-child) {
                        &::after {
                            display: none;
                        }
                    }
                    a {
                        padding: 0 .5rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

.subfooter {
    font-size: 1.4rem;
    color: $clr-gray;

    a {
        color: $clr-navy;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(lg) {
        font-size: 1rem;
    }
}