.block-table {
    margin-bottom: 3rem;
    
	table { // Global
		margin-bottom: 0;
		color: $clr-black;
        width: 100%;

		th,
		td {
			border: none;
			padding: 0;

			// @media (min-width: 768px)
			@include media-breakpoint-up(md) {
				padding: 1rem 1.4rem;
			}
		}

		td {
			//
		}

		thead {
			border: none; // block editor

			th {
				font-family: $poppins;
				font-size: 1.8rem;
				font-weight: 500;
				line-height: 2.4rem;
				background-color: $clr-navy;
				color: $clr-white;
			}
		}

		tbody {
			tr {
				&:nth-of-type(even) {
					td {
						background-color: $clr-gray-light;
					}
				}
			}
		}

		tfoot {
			border: none; // block editor

			tr {
				background-color: $clr-gray;
			}

			td {
				color: $clr-white;
			}
		}
	}

	table.bt { // Mobile
		// Responsive Tables CSS https://github.com/jerrylow/basictable
		thead {
			display: none;
		}

		td {
			border: none;
			display: flex;

			&::before {
				font-family: $poppins;
				color: $clr-white;
				background-color: $clr-navy;
				content: attr(data-th);
				flex-shrink: 0;
				font-size: 1.8rem;
				line-height: 1.9rem;
				font-weight: 500;
				width: (133 / 280 * 100%);
			}

			&::before,
			.bt-content {
				padding: 1.2rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}
		}

		tbody {
			//
		}

		tfoot {
			//
		}

		&.bt--no-header {
			td {
				&::before {
					content: none;
				}
			}
		}
	}

}
