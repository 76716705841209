.notifbar {
    background-color: $clr-gold;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.8rem;

    a {
        color: $clr-black;
    }

    @include media-breakpoint-down(xl) {
        font-size: 1.5rem;
    }
}

.topmenu {
    background-color: $clr-navy;
    color: $clr-white;
    border-bottom: 9px solid $clr-blue;
    position: relative;
    z-index: $zindex-dropdown;
    padding: .5rem 0;
    
    ul {
        > li {
            display: inline-flex;
            padding-left: 1rem;
            &::after {
                content: " ";
                background-color: $clr-topmenu-divider;
                height: 60%;
                width: 2px;
                align-self: center;
                margin-left: 1rem;
            }
            &:last-child::after {
                content: none;
            }
            &:nth-last-child(2)::after {
                content: none;
            }
            a.nav-link {
                color: $clr-white;
                font-weight: normal;
                font-size: 1.8rem;

                &:hover {
                    color: $clr-white;
                    text-decoration: underline;
                }

                @include media-breakpoint-down(xl) {
                    font-size: 1.5rem;
                }
            }
            a.top-search {
                transition: .25s ease-in-out;

                &:hover {
                    transform: rotate(90deg);
                    transition: .25s ease-in-out;
                }
            }

        }
    }

    
    .btn-apply {
        background-color: $clr-gold;
        color: $clr-black;
        text-decoration: none;
        padding: .5rem 3rem;

        &:hover {
            text-decoration: none;
            background-color: $clr-gold;
            background-image: var(--bs-gradient);
            border-color: $clr-gold;
        }

        @include media-breakpoint-down(lg) {
            background-color: transparent;
            font-family: $lemonmilk;
            font-size: 1.3rem;
            text-align: center;
            width: 100%;
            color: $clr-white;
            box-shadow: none;

            span {
                display: none;
            }

            &::after {
                content: "Apply To SCC";
            }
        }
    }
}

#mainmenu {
    background: rgba(255,255,2555,.9);
    z-index: $zindex-sticky;

    &.fixed-top {
        padding-top: 1rem;
        padding-bottom: 1rem;
        transition: .25s ease-in-out;

        .navbar-brand {
            width: 150px;

            @include media-breakpoint-up(xxl) {
                width: 240px;
            }
        }
    }
    .navbar-brand {
        width: 240px;
        img {
            width: 100%;
        }

        @include media-breakpoint-up(xxl) {
            width: 320px;
        }
        @include media-breakpoint-down(xl) {
            width: 205px;
        }
        @include media-breakpoint-down(lg) {
            width: 180px;
        }
    }
    .navbar-toggler {
        padding: 0;

        &:focus {
            box-shadow: 0 0 0 0.1rem;
        }
    }
    .offcanvas {
        box-shadow: none;

        &-header {
            width: 100%;
            align-content: space-between;

            .btn-close {
                background-size: cover;
                width: .8rem;
                height: .8rem;
                margin-right: 0;
            }
        }

        &.show {
            .offcanvas-body {
                width: 100%;

                ul {
                    li {
                        a.nav-link {
                            text-align: left;
                            font-weight: 500;
                            border-bottom: 1px solid #cccccc;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            &.dropdown-toggle {
                                &::after {
                                    content: '\2b';
                                    font-family: 'Font Awesome 6 Pro';
                                    font-size: 16px;
                                    font-weight: 500;
                                    border: none;
                                }
                                &.show::after {
                                    content: '\f068';
                                }
                            }
                        }
                    }
                }
            }

            .dropdown-menu {
                box-shadow: unset;
                border: none;
                border-top: 1px solid $clr-gray;
                border-bottom: 1px solid $clr-gray;
                border-radius: 0;
            }
        }
    }
    ul {
        > li {
            display: inline-flex;
            padding-left: 1rem;
            &:not(:last-child) {
                &::after {
                    content: " ";
                    background-color: $clr-mainmenu-divider;
                    height: 60%;
                    width: 2px;
                    align-self: center;
                    margin-left: 1rem;

                    @include media-breakpoint-down(lg) {
                        content: none;
                    }
                }
            }
            a.nav-link {
                color: $clr-black;
                font-size: 1.8rem;
                text-align: center;

                &:hover {
                    color: $clr-blue;
                }

                @include media-breakpoint-down(xl) {
                    font-size: 1.5rem;
                }
            }

            @include media-breakpoint-down(lg) {
                display: block;
                padding-left: unset;
            }
        }
    }
}

@media all and (min-width: 992px) {
	.dropdown-menu li{ 
        width: 100%; 
    }
	.nav-item .submenu{ 
		position: absolute;
		left:100%; top: -1px;
        height: calc(100% + 2px);
        box-shadow: none;
        border-left: none;

        &::before {
            content: ' ';
            position: absolute;
            top: 1.5rem;
            left: 0;
            width: 2px;
            height: 150px;
            background-color: $clr-blue;
        }
	}
	.nav-item .submenu-left{ 
		right:100%; left:auto;
	}
    .dropdown-menu > li:hover { background: transparent; }
	.dropdown-menu > li:hover > a { color: $clr-blue; background: transparent; }
	.dropdown-menu > li:hover > .submenu{ display: block; }
}	

.animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@keyframes slideIn {
    0% {
        transform: translateY(1.5rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }

    0% {
        transform: translateY(1.5rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }

    0% {
        -webkit-transform: translateY(1.5rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}