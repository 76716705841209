.juicer-feed h1.referral {display: none;}
.hero {
    position: relative;
    height: 920px;
    overflow: hidden;

    @include media-breakpoint-down(xl) {
        height: 720px;
    }
    @include media-breakpoint-down(md) {
        height: auto;
    }
    
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__video {
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 100%; 
            min-height: 100%;
            
            &__controls {
                position: absolute;
                bottom: 4rem;
                right: 10rem;

                button {
                    background: $clr-navy;
                    color: $clr-white;
                    border: none;
                    width: 5rem;
                    height: 5rem;
                    font-size: 3rem;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            position: relative;
            height: 469px;

            + .container {
                padding-left: 0;
                padding-right: 0;
                max-width: 100%;
            }
        }
    }

    &__text {        
        color: $clr-white;
        padding: 4rem;
        position: relative;
        top: 40%;
        z-index: 1;

        &::before {
            content: ' ';
            background-color: rgba(39,144,192,.8);
            position: absolute;
            width: 100%;
            height: 100%;
            margin-left: -4rem;
            z-index: -1;
            top: 0;

        }

        h2 {
            font-family: $lemonmilk;
            font-size: 3.5rem;
            font-weight: 500;
            line-height: 5.5rem;
            color: $clr-white;
        }

        &-underline {
            white-space: nowrap;
            position: relative;
            
            &::after {
                content: ' ';
                background: url(../images/underline-navy.png) no-repeat top center;
                background-size: contain;
                width: 100%;
                height: 12px;
                position: absolute;
                bottom: -15px;
                left: 0;
            }
        }

        &-blue {
            &::before {
                background-color: rgba(39,144,192,.8);
            }

            .hero__text-underline::after {
                background: url(../images/underline-navy.png) no-repeat top center;
                background-size: contain;
            }
        }

        &-green {
            &::before {
                background-color: rgba(4,126,141,.8);
            }
            .hero__text-underline::after {
                background: url(../images/underline-gold.png) no-repeat top center;
                background-size: contain;
            }
            .btn-primary {
                background-color: $clr-gold;
                border-color: $clr-gold;
                color: $clr-navy;
            }
        }

        &-purple {
            &::before {
                background-color: rgba(98,39,99,.8);
            }
            .hero__text-underline::after {
                background: url(../images/underline-gold.png) no-repeat top center;
                background-size: contain;
            }
            .btn-primary {
                background-color: $clr-gold;
                border-color: $clr-gold;
                color: $clr-navy;
            }
        }

        @include media-breakpoint-down(lg) {
            top: 0;
            padding: 2rem;

            &-blue {
                background-color: rgba(39,144,192,.8);
            }
    
            &-green {
                background-color: rgba(4,126,141,.8);
            }
    
            &-purple {
                background-color: rgba(98,39,99,.8);
            }

            &::before {
                display: none;
            }
        }
    }

}

.glance {
    h2 {
        font-family: $lemonmilk;
        font-size: 4.5rem;
        font-weight: 500;
        color: $clr-navy;
    }
    &__box {
        &__inner {
            color: $clr-white;
            padding: 4rem 2rem;
        }
        &__wrapper:nth-child(odd) &__inner {
            height: 28rem;
        }
        &__wrapper:nth-child(even) &__inner {
            height: 35rem;
        }
        &__number {
            font-family: $lemonmilk;
            font-size: 9rem;
            font-weight: 500;
            line-height: 8rem;

            &__small {
                font-size: 5.6rem;

                @include media-breakpoint-down(xl) {
                    font-size: 4.6rem;
                }
            }

            @include media-breakpoint-down(xl) {
                font-size: 6.5rem;
            }
        }
        &__line {
            background-color: $clr-white;
            height: 5px;
            width: 70%;
            margin: 2rem auto;
        }
        &__desc {
            font-family: $lemonmilk;
            font-size: 2.1rem;
            padding: 0 2rem;
        }
    }
    @include media-breakpoint-down(lg) {
        .container {
            padding-left: 0;
            padding-right: 0;
        }
        &__box {
            &__inner {
                padding: 4rem 2rem;
            }
            &__wrapper:nth-child(odd) &__inner, &__wrapper:nth-child(even) &__inner {
                height: 200px;
            }
            &__number {
                font-size: 4rem;
                line-height: 4rem;

                &__small {
                    font-size: 2.4rem;
                }
            }
            &__desc {
                font-size: 1.2rem;
                padding: 0;
            }
            &__line {
                margin: 1rem auto;
            }
        }
    }
}

.infobox {
    position: relative;

    &::before {
        content:"";
        position: absolute;
        z-index: -1;
        background-color: rgba(0,0,0,.03);
        bottom: 0;
        margin-left: calc(-50vw - -50%);
        top: 5%;
        width: 100vw;
        height: 86%;
    }

    &__wrapper {
        border-top: 9px solid $clr-blue;
    }

    &__image {
        img {
            width: 100%;
            height: 300px;
            object-fit: cover;            

            @include media-breakpoint-down(xl) {
                height: 250px;
            }
        }
    }

    &__content {
        background-color: $clr-white;
        text-align: center;
        padding: 4rem 3rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

        h2 {
            font-family: $lemonmilk;
            font-size: 2.5rem;
            font-weight: 500;
            line-height: 2.5rem;
            letter-spacing: 3px;
            color: $clr-navy;
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2rem;
            height: 116px;
        }

    }
}

.location {
    overflow: hidden;

    &__bg-color {
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__text {
        padding-left: 5rem;
        position: relative;

        h2 {
            font-family: $lemonmilk;
            font-size: 3.5rem;
            font-weight: 500;
            color: $clr-black;
        }

        &::before {
            content: ' ';
            position: absolute;
            width: .8rem;
            height: 5.5rem;
            background-color: $clr-blue;
            left: 2rem;
            top:0;
        }
    }

    @include media-breakpoint-down(lg) {
        &__text {
            padding-left: 3rem;
            margin-bottom: 2rem;

            &::before {
                left: 1rem;
                height: 3rem;
                width: 7px;
            }
        }
    }
}

.news {
    &__title {
        h2 {
            font-family: $lemonmilk;
            font-size: 3.5rem;
            font-weight: 500;
            padding-left: 3rem;
            position: relative;
            color: $clr-black;

            &::before {
                content: ' ';
                position: absolute;
                width: 0.8rem;
                height: 5.5rem;
                background-color: $clr-blue;
                left: 0;
                top:0;
            }
        }
    }

    &__main {
        position: relative;

        &-image {
            height: 53rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-content {
            position: absolute;
            background-color: rgba(0,0,0,.59);
            padding: 3rem;
            top: 8%;
            left: 0;

            &-date {
                color: $clr-white;
                margin-bottom: 1rem;
            }
            &-title {
                h3 {
                    font-family: $poppins;
                    font-weight: 500;
                    color: $clr-white;
                    font-size: 2.5rem;
                    line-height: 3rem;
                }
            }
            &-excerpt {
                color: $clr-white;
                font-size: 1.4rem;
            }
        }
    }

    &__carousel {
        &__item {
            padding: 2rem;
            &-image {
                img {
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }
            }
            &-wrapper {
                background-color: #fff;
                padding: 2rem;
                box-shadow: rgb(0 0 0 / 13%) 0px 4px 18px;
            }
            &-title {
                a {
                    text-decoration: none;
                    h4 {
                        font-size: 2.1rem;
                        color: $clr-black;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
                
            }
            &-date {
                color: $clr-gray;
                margin-bottom: 1rem;
            }
            &-excerpt {
                font-size: 1.4rem;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &__title {
            h2 {
                font-size: 2rem;
                padding-left: 2rem;

                &::before {
                    height: 3rem;
                    width: 7px;
                }
            }
        }

        &__carousel .owl-theme .owl-nav {
            display: none;
        }
    }
}

.events {
    background-color: $clr-gray-light;

    &__title {
        h2 {
            font-family: $lemonmilk;
            font-size: 3.5rem;
            font-weight: 500;
            padding-left: 3rem;
            position: relative;
            color: $clr-black;

            &::before {
                content: ' ';
                position: absolute;
                width: .8rem;
                height: 5.5rem;
                background-color: $clr-blue;
                left: 0;
                top:0;
            }
        }
    }
    
    &__item {
        box-shadow: rgb(0 0 0 / 13%) 0px 4px 18px;
        
        &__date {
            background-color: $clr-green;
            color: $clr-white;
            text-align: center;
            padding: 1rem;
            &-month {
                font-size: 1.8rem;
                text-transform: capitalize;
                font-weight: 500;
            }
            &-date {
                font-size: 4rem;
                font-weight: 600;
                line-height: 4rem;
            }
            &-time {
                font-size: 1.1rem;
            }
        }
        &__title {
            padding: 2rem 1rem 2rem 0;
            font-size: 2rem;
            font-weight: 500;

            a {
                color: $clr-black;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            @include media-breakpoint-down(xl) {
                font-size: 1.8rem;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &__title {
            h2 {
                font-size: 2rem;
                padding-left: 2rem;

                &::before {
                    height: 3rem;
                    width: 7px;
                }
            }
        }

        &__item {
            &__date {
                height: 100%;

                &-month {
                    font-size: 1rem;
                }
                &-date {
                    font-size: 3rem;
                }
                &-time {
                    font-size: 1rem;
                }
            }
            &__title {
                font-size: 1.2rem;
            }
        }
    }
}

.students_life {

    &__wrapper {
        box-shadow: rgb(0 0 0 / 13%) 0px 4px 18px;
        padding: 3rem;
    }

    &__title {
        h2 {
            font-family: $lemonmilk;
            font-size: 3.5rem;
            font-weight: 500;
            padding-left: 3rem;
            position: relative;
            color: $clr-black;

            &::before {
                content: ' ';
                position: absolute;
                width: .8rem;
                height: 5.5rem;
                background-color: $clr-blue;
                left: 0;
                top:0;
            }
        }
    }

    &__content {
        font-size: 2rem;
        font-weight: 500;
    }

    &__image {
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    @include media-breakpoint-down(lg) {
        &__wrapper {
            padding: 3rem 0;
        }

        &__title {
            h2 {
                font-size: 2rem;
                padding-left: 2rem;

                &::before {
                    height: 3rem;
                    width: 7px;
                }
            }
        }
    }
}

.testimonial {
    &__name {
        h2 {
            font-family: $lemonmilk;
            color: $clr-black;
            font-weight: 500;
            margin-bottom: 1rem;
        }
    }
    &__class {
        font-size: 1.8rem;
        font-weight: 500;
        color: $clr-gray;
        padding-bottom: 2rem;
    }
    &__quote {
        font-size: 1.6rem;
        font-weight: 500;
        color: $clr-black;

        &::before {
            content: ' ';
            width: 67px;
            height: 50px;
            background: url(../images/quote.png) no-repeat top center;
            background-size: cover;
            margin-bottom: 2rem;
            display: block;
        }
    }
    &__image {
        background: url(../images/bg-testimonial.png) no-repeat top right;
        padding-top: 4rem;
        padding-right: 4rem;
        img {
            width: 100%;
            object-fit: cover;
        }

        &__video {
            overflow: hidden;
            width: 100%;
            height: 100%;

            & video {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .container {
            padding-left: 0;
            padding-right: 0;
        }
        &__image {
            padding: 0;
        }
    }
}
