.subpage-hero {
    height: max-content;
    max-height: 634px;
    margin-top: -137px;
    position: relative;
    overflow: hidden;

    div {
        max-height: inherit;
    }
    &__image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: inherit;
        }
    }

    &__text {
        color: $clr-white;
        position: absolute;
        bottom: 5rem;
        width: 100%;
        
        h1 {
            color: $clr-white;
        }
    }

    @include media-breakpoint-down(lg) {
        margin-top: 0;
        margin-bottom: 0;
        max-height: unset;

        &__text {
            background-color: $clr-purple;
            position: relative;
            padding: 2rem 0 1rem 0;
            bottom: unset;
        }
    }

    @include media-breakpoint-up(xxxl) {
        margin-top: -150px;
    }
}