html {
	font-size: 62.5%;

    @include media-breakpoint-up(xxl) {
        font-size: 82.5%;
    }
    @include media-breakpoint-up(xxxl) {
        font-size: 100%;
    }
}

body {
	font-size: 1.6rem;
	line-height: (23 / 16);

	// @media (min-width: 768px)
	@include media-breakpoint-up(md) {
		font-size: 1.7rem;
		line-height: (24 / 17);
	}

	&.nav-open {
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
}

img {
	max-width: 100%;
}

a {
	transition: 0.25s ease-in-out;
}

h1 {
    font-family: $lemonmilk;
    letter-spacing: 2px;
}
h2, h3, h4, h5 {
    font-family: $poppins;
}
h1 {
    font-size: 3.6rem;
    font-weight: 500;
    color: $clr-navy;
}
h2 {
    font-size: 4.2rem;
    font-weight: bold;
    color: $clr-navy;
    margin-bottom: 2rem;
}
h3 {
    font-size: 3.3rem;
    font-weight: 600;
    color: $clr-navy;
    margin-bottom: 1rem;

    @include media-breakpoint-down(xl) {
        font-size: 3rem;
    }
}
h4 {
    font-size: 2.8rem;
    font-weight: 600;
    color: $clr-navy;
    margin-bottom: 1rem;

    @include media-breakpoint-down(xl) {
        font-size: 2.3rem;
    }
}
h5 {
    font-size: 2rem;
    font-weight: 600;
    color: $clr-navy;
    margin-bottom: 1rem;

    @include media-breakpoint-down(xl) {
        font-size: 1.8rem;
    }
}

p {
    font-size: 1.8rem;
    line-height: 3rem;
    margin-bottom: 3rem;

    @include media-breakpoint-down(xl) {
        font-size: 1.6rem;
    }
}
article {
    padding-bottom: 3rem;
}
figure {
    margin-bottom: 3rem;
    img {
        max-width: 100%;
    }
}
figcaption {
    font-size: 1.4rem;
}
ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    padding-left: 0;
    
    li {
        counter-increment: my-awesome-counter;
        position: relative;
        padding-left: 3rem;
        margin-bottom: 1.5rem;

        &::before {
            content: counter(my-awesome-counter) ". ";
            color: $clr-green;
            font-size: 2rem;
            font-weight: bold;
            margin-right: 1rem;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.mainContent {
   // overflow-x: hidden;
}
.table {
    font-size: inherit;
}
.btn {
    font-weight: 500;
    padding: 1rem 3rem;
    text-transform: capitalize;

    @include media-breakpoint-down(md) {
        padding: 1rem;
    }
}
.btn-primary {
    background-image: none;
}
.btn-secondary {
    background-color: transparent;
    background-image: none;
    border: 2px solid $clr-white;
    color: $clr-white;
}

.btn-warning {
    background-color: $clr-gold;
    background-image: none;
    color: $clr-navy !important;
    font-weight: 600;
}

.btn-outline-warning {
    @include button-outline-variant($clr-gold, #222222, lighten($clr-gold,5%), $clr-gold);
}
.btn-link {
    text-decoration: none;
}
.btn-arrow {
    padding-right: 60px;
    position: relative;

    &::after {
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z'/%3E%3C/svg%3E");
        filter: invert(100%);
        width: 39px;
        height: 19px;
        position: absolute;
        right: 1.5rem;
        top: calc(50% - 12px);
        transition: .25s ease-in-out;
    }
    &:hover::after {
        right: 1rem;
        transition: .25s ease-in-out;
    }
    
    @include media-breakpoint-down(lg) {
        &::after {
            top: calc(50% - 11px);
        }
    }
}
.btn-link.btn-arrow {
    &::after {
        filter: invert(12%) sepia(55%) saturate(3097%) hue-rotate(202deg) brightness(98%) contrast(103%);
        right: 1rem;
        transition: .25s ease-in-out;
    }
    &:hover {
        background: none;
        &::after {
            right: .5rem;
            transition: .25s ease-in-out;
        }
    }
}
.bg {
    &-gray {
        background-color: $clr-gray;
    }
    &-gray-light {
        background-color: $clr-gray-light;
    }
    &-green {
        background-color: $clr-green;
    }
    &-orange {
        background-color: $clr-orange;
    }
    &-blue {
        background-color: $clr-blue;
    }
    &-purple {
        background-color: $clr-purple;
    }
    &-navy {
        background-color: $clr-navy;
    }
    &-blue-light {
        background-color: $clr-blue-bg;
    }
    &-orange-light {
        background-color: $clr-orange-bg;
    }
    &-green-light {
        background-color: $clr-green-bg;
    }
}

.text {
    &-navy {
        color: $clr-navy;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.8rem;

        e {
            width: 32px;
            text-align: center;
        }
    }
    &-blue {
        color: $clr-blue;
        font-weight: 600;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.green-box {
    background-color: $clr-green-bg;
    padding: 2rem;
    border-radius: .5rem;

    &__title {
        h3 {
            font-size: 2rem;
            font-weight: 600;
            color: $clr-green;
            margin: 0 0 1rem 0
        }
    }
    &__content {
        color: $clr-black;
        font-size: 1.6rem;
    }
}

.column-2 {
    column-count: 2;

    @include media-breakpoint-down(md) {
        column-count: 1;
    }
}

.dropdown-toggle:after {
    content: none;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
}

.view__pagination {
    a.active {
        text-decoration: none;
        font-weight: bold;
    }
}