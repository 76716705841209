.accordion {
    &-button, &-button:not(.collapsed) {
        background-color: $clr-navy;
        padding: 1rem;
        font-family: $poppins;
        font-size: 2.2rem;
        color: $clr-white;
        font-weight: 600;

        &::after {
            filter: invert(1);
        }
        
        &:focus {
            text-decoration: underline;
        }

    }

    &-header {
        margin: 0;
    }

}