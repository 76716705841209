.sidebar {
    color: $clr-white;
    padding: 2rem 1rem 5rem 1rem;
    position: relative;
    height: 100%;

    &::after {
        content: ' ';
        width: calc(50vw - 55%);
        height: 100%;
        background-color: #002d69;
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
    }

    a {
        color: $clr-white;
    }
    section {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    h2 {
        font-family: $lemonmilk;
        font-weight: 500;
        color: $clr-white;
        font-size: 2.7rem;
        padding-left: 2rem;
        position: relative;

        &::before {
            content: ' ';
            position: absolute;
            width: .5rem;
            height: 3rem;
            background-color: $clr-blue;
            left: 0;
            top:0;
        }
    }

    &__nav {
        nav {
            ul {
                list-style: none;
            }

            & > ul {

                & > li {
                    font-size: 2.1rem;
                    line-height: 3.5rem;

                    @include media-breakpoint-down(xl) {
                        font-size: 1.6rem;
                    }

                    & > ul {
                        & > li {
                            font-size: 1.8rem;

                            @include media-breakpoint-down(xl) {
                                font-size: 1.4rem;
                            }
        
                            & > ul {
                                & > li {
                                    font-size: 1.7rem;

                                    @include media-breakpoint-down(xl) {
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            a {
                color: $clr-white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
                
            }

            li.active a {
                text-decoration: underline;
            }
        }
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__numbers {
        text-align: center;
        margin-bottom: 3rem;
        &-number {
            font-family: $lemonmilk;
            font-weight: bold;
            font-size: 8.4rem;
            line-height: 11rem;
            color: $clr-gold;
        
            &-small {
                font-size: 5rem;
                color: $clr-gold;
            }
        }
        &-text {
            font-size: 2rem;
            font-weight: 500;
            color: $clr-white;
        }
    }

    &__buttons {
        text-align: center;
    }

    &__testimony {
        background-color: $clr-green;
        border-radius: 20px;
        text-align: center;
        padding: 3rem;
        &__image {
            width: 195px;
            height: 195px;
            margin: 0 auto;
            border-radius: 50%;
            margin-bottom: 2rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__name {
            font-weight: bold;
        }
    }

    &__events {
        &_item {
            background-color: $clr-white;
            margin-bottom: 3rem;

            .events__item {
                &__date-month {
                    font-size: 1.5rem;
                }
                &__date-date {
                    font-size: 3rem;
                }
                &__date-time {
                    font-size: 1rem;
                }

                &__title {
                    font-size: 1.5rem;
                    padding: 1rem;
                }
            }
        }
    }
}