.students_page {
    &__header {
        background: #ededed url(../images/bg_logo2.png) no-repeat top center;
        padding-top: 3rem;
        position: relative;

        .container {
            position: relative;
            z-index: 2;
        }
        &::after {
            content: ' ';
            width: 100%;
            height: 10%;
            position: absolute;
            bottom: 0;
            background-color: $clr-white;
            z-index: 1;
        }
        h1 {
            color: $clr-navy;
            margin-bottom: 1rem;
        }
        .breadcrumb {
            padding-top: 0;
            padding-bottom: 2rem;
        }
        .btn {
            padding: 1rem 2rem;
        }
        .sidebar__events_item {
            box-shadow: rgb(0 0 0 / 13%) 0px 4px 18px;
        }
    }

    &__group {
        margin-bottom: 5rem;
        &-links {
            ul {
                list-style: none;
                padding: 0;

                li {
                    a {
                        text-decoration: none;
                        
                        &:hover {
                            text-decoration: underline;
                        }
                        
                        e {
                            width: 32px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        &-title {
            margin-bottom: 2rem;

            h2 {
                font-family: $lemonmilk;
                font-weight: 500;
                font-size: 2.6rem;
                margin-bottom: 1rem;
            }
            &::after {
                content: ' ';
                width: 100%;
                height: 10px;
                display: block;
                background: -webkit-linear-gradient(90deg, $clr-navy 10%, $clr-blue 10%);
                background: -o-linear-gradient(90deg, $clr-navy 10%, $clr-blue 10%);
                background: -moz-linear-gradient(90deg, $clr-navy 10%, $clr-blue 10%);
                background: linear-gradient(90deg, $clr-navy 10%, $clr-blue 10%);
            }
        }
    }
}