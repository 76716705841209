// ——————————————————————————————————————
//	Bootstrap Overrides
// ——————————————————————————————————————
// @NOTE – refer to /node_modules/bootstrap/scss/_variables.scss

$enable-responsive-font-sizes: true;

// Font Size
$font-size-base: 1.6rem;

// Close Button
$btn-close-width: 1rem;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 1rem;
$btn-close-padding-y: $btn-close-padding-x;

// Spacing
$spacer: 3.2rem;


// Paragraphs
$paragraph-margin-bottom:   1rem * 1.6;


// Grid breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1080px,
	xl: 1200px,
	xxl: 1920px,
	xxxl: 2600px
);


// Grid containers
$container-max-widths: (
	xl: 1280px,
	xxl: 1700px,
	xxxl: 2330px
); // Having only the xl width assures a fluid layout


// Grid columns
$grid-gutter-width: 40px;


// Components
$border-radius:               .25rem * 1.6;
$border-radius-lg:            .3rem * 1.6;
$border-radius-sm:            .2rem * 1.6;

$rounded-pill:                50rem * 1.6;

$box-shadow-sm:               0 .125rem * 1.6 .25rem * 1.6 rgba(#000, .075);
$box-shadow:                  0 .5rem * 1.6 1rem * 1.6 rgba(#000, .15);
$box-shadow-lg:               0 1rem * 1.6 3rem * 1.6 rgba(#000, .175);


// Typography
$font-size-base:              1rem * 1.6;

$display1-size:               6rem * 1.6;
$display2-size:               5.5rem * 1.6;
$display3-size:               4.5rem * 1.6;
$display4-size:               3.5rem * 1.6;

$kbd-box-shadow:              inset 0 -.1rem * 1.6 0 rgba(#000, .25);

$list-inline-padding:         .5rem * 1.6;


// Tables
$table-cell-padding:          .75rem * 1.6;
$table-cell-padding-sm:       .3rem * 1.6;


// Buttons + Forms
$input-btn-padding-y:         .375rem * 1.6;
$input-btn-padding-x:         .75rem * 1.6;

$input-btn-focus-width:       .2rem * 1.6;

$input-btn-padding-y-sm:      .25rem * 1.6;
$input-btn-padding-x-sm:      .5rem * 1.6;

$input-btn-padding-y-lg:      .5rem * 1.6;
$input-btn-padding-x-lg:      1rem * 1.6;

$btn-block-spacing-y:         .5rem * 1.6;

$label-margin-bottom:                   .5rem * 1.6;
$form-text-margin-top:                  .25rem * 1.6;

$form-check-input-gutter:               1.25rem * 1.6;
$form-check-input-margin-y:             .3rem * 1.6;
$form-check-input-margin-x:             .25rem * 1.6;

$form-check-inline-margin-x:            .75rem * 1.6;
$form-check-inline-input-margin-x:      .3125rem * 1.6;

$form-group-margin-bottom:              1rem * 1.6;

$custom-control-gutter:                 .5rem * 1.6;
$custom-control-spacer-x:               1rem * 1.6;

$custom-control-indicator-size:         1rem * 1.6;

$custom-select-indicator-padding:   1rem * 1.6;

$custom-range-track-height:         .5rem * 1.6;
$custom-range-track-border-radius:  1rem * 1.6;
$custom-range-track-box-shadow:     inset 0 .25rem * 1.6 .25rem * 1.6 rgba(#000, .1);

$custom-range-thumb-width:                   1rem * 1.6;
$custom-range-thumb-border-radius:           1rem * 1.6;
$custom-range-thumb-box-shadow:              0 .1rem * 1.6 .25rem * 1.6 rgba(#000, .1);


// Navs
$nav-link-padding-y:                .5rem * 1.6;
$nav-link-padding-x:                1rem * 1.6;

$navbar-nav-link-padding-x:         .5rem * 1.6;

$navbar-toggler-padding-y:          .25rem * 1.6;
$navbar-toggler-padding-x:          .75rem * 1.6;


// Dropdowns
$dropdown-min-width:                10rem * 1.6;
$dropdown-padding-y:                .5rem * 1.6;
$dropdown-spacer:                   .125rem * 1.6;
$dropdown-box-shadow:               0 .5rem * 1.6 1rem * 1.6 rgba(#000, .175);

$dropdown-item-padding-y:           .25rem * 1.6;
$dropdown-item-padding-x:           1.5rem * 1.6;


// Pagination
$pagination-padding-y:              .5rem * 1.6;
$pagination-padding-x:              .75rem * 1.6;
$pagination-padding-y-sm:           .25rem * 1.6;
$pagination-padding-x-sm:           .5rem * 1.6;
$pagination-padding-y-lg:           .75rem * 1.6;
$pagination-padding-x-lg:           1.5rem * 1.6;


// Cards
$card-spacer-y:                     .75rem * 1.6;
$card-spacer-x:                     1.25rem * 1.6;

$card-img-overlay-padding:          1.25rem * 1.6;

$card-columns-gap:                  1.25rem * 1.6;


// Tooltips
$tooltip-padding-y:                 .25rem * 1.6;
$tooltip-padding-x:                 .5rem * 1.6;

$tooltip-arrow-width:               .8rem * 1.6;
$tooltip-arrow-height:              .4rem * 1.6;


// Popovers
$popover-box-shadow:                0 .25rem * 1.6 .5rem * 1.6 rgba(#000, .2);

$popover-header-padding-y:          .5rem * 1.6;
$popover-header-padding-x:          .75rem * 1.6;

$popover-arrow-width:               1rem * 1.6;
$popover-arrow-height:              .5rem * 1.6;


// Toasts
$toast-padding-x:                   .75rem * 1.6;
$toast-padding-y:                   .25rem * 1.6;
$toast-font-size:                   .875rem * 1.6;

$toast-box-shadow:                  0 .25rem * 1.6 .75rem * 1.6 rgba(#000, .1);


// Modals
$modal-inner-padding:               1rem * 1.6;

$modal-dialog-margin:               .5rem * 1.6;
$modal-dialog-margin-y-sm-up:       1.75rem * 1.6;

$modal-content-box-shadow-xs:       0 .25rem * 1.6 .5rem * 1.6 rgba(#000, .5);
$modal-content-box-shadow-sm-up:    0 .5rem * 1.6 1rem * 1.6 rgba(#000, .5);

$modal-header-padding-y:            1rem * 1.6;
$modal-header-padding-x:            1rem * 1.6;


// Alerts
$alert-padding-y:                   .75rem * 1.6;
$alert-padding-x:                   1.25rem * 1.6;
$alert-margin-bottom:               1rem * 1.6;


// Progress bars
$progress-height:                   1rem * 1.6;
$progress-box-shadow:               inset 0 .1rem * 1.6 .1rem * 1.6 rgba(#000, .1);


// List group
$list-group-item-padding-y:         .75rem * 1.6;
$list-group-item-padding-x:         1.25rem * 1.6;


// Image thumbnails
$thumbnail-padding:                 .25rem * 1.6;


// Breadcrumbs
$breadcrumb-padding-y:              .75rem * 1.6;
$breadcrumb-padding-x:              1rem * 1.6;
$breadcrumb-item-padding-x:         .5rem * 1.6;

$breadcrumb-margin-bottom:          1rem * 1.6;


// Spinners
$spinner-width:         2rem * 1.6;

$spinner-width-sm:        1rem * 1.6;


// Code
$kbd-padding-y:                     .2rem * 1.6;
$kbd-padding-x:                     .4rem * 1.6;


// Buttons
$btn-focus-box-shadow: none;


// Options
$enable-caret: false;
$enable-rounded: false;
